import React from 'react';
import LandingPageTemplate from '../../components/LandingPageTemplate';

import mainImage from '../../images/landingpage_covid_1600_900_v2.webp';


const context = {
  title: 'COVID-19 - Sicherheit durch Automatiktüren', // Große Haupt-Überschrift
  subTitle: 'Automatiktür24 - aktuelle Themen', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Automatische Türen für gemeinsame Sicherheit.',
  image: mainImage, //Haupt-Bild der Landingpage
  imageAlt: "", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Automatiktür COVID-19 Beratung anfordern",
  suggestedProducts: [
    '/produkte/automatische-schiebetuer/',
    '/produkte/karusselldrehtuer/',
    '/produkte/automatische-drehtuer/'
  ],
  text: 'Die aktuelle Situation mit dem Coronavirus / COVID-19 stellt uns alle vor große Herausforderungen. Wir von Automatiktür24 unterstützen und beraten Sie gerne bei der Wahl Ihrer neuen automatischen Schiebetür zu wirtschaftlichen Preisen.\n\n' +
    'Lassen Sie Ihre neue Automatiktür mit Sensor und Näherungstaster kontaktlos öffnen und sorgen Sie so für mehr Hygiene, Sicherheit und Komfort.\n\n' +
    'Egal ob mit Rollstuhl, Kinderwagen, oder Großeinkauf - mit der passenden Automatiktür bieten Sie Ihren Kunden ein barrierefreies Einkaufserlebnis.\n\n' +
    'Gut fürs Klima. Zudem arbeitet eine gut abgestimmte Automatiktür enorm energieffizient. Ermöglichen Sie Frischluftzirkulation ohne, dass Ihr komplettes Gebäude auskühlt.\n\n' +
    '#automatiktür #barrierefrei #hygiene #kontaktlos - gemeinsam sicher.',
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
